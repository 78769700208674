import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appAvatar]'
})
export class AvatarDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {

    // const colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#2980b9',
    //   '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d'];

    const colors = ['bg-red-500', 'bg-pink-500', 'bg-purple-500', 'bg-deep-purple-500', 'bg-indigo-500', 'bg-blue-500',
                    'bg-light-blue-500', 'bg-cyan-500', 'bg-green-500', 'bg-light-green-500', 'bg-lime-500', 'bg-amber-500',
                    'bg-orange-500', 'bg-deep-orange-500', 'bg-brown-500', 'bg-blue-grey-500', 'bg-grey-500', 'bg-red-a200',
                    'bg-pink-a400', 'bg-purple-a400', 'bg-deep-purple-a400', 'bg-indigo-a400', 'bg-blue-a400', 'bg-light-blue-a400',
                    'bg-cyan-a400', 'bg-green-a400', 'bg-light-green-a400', 'bg-lime-a400', 'bg-yellow-a400', 'bg-amber-a400',
                    'bg-orange-a400', 'bg-deep-orange-a400'];

    const name = this.el.nativeElement.getAttribute('data-name');
    const initials = this.getInitials(name);

    if (initials.length === 2) {
      const avatarWidth = this.el.nativeElement.getAttribute('width');
      const avatarHeight = this.el.nativeElement.getAttribute('height');

      // replace array of colors with linear-gradient values
      // this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', 'linear-gradient(135deg,#673ab7 0,#2196f3 100%');
      // this.renderer.setStyle(this.el.nativeElement, 'backgroundColor',
      //         colors[this.calcNmberFromText(initials) % colors.length]); // => "#DC2A2A"

      this.renderer.setAttribute(this.el.nativeElement, 'class', colors[this.calcNumberFromText(initials) % colors.length]);

      this.renderer.setStyle(this.el.nativeElement, 'width', avatarWidth + 'px');
      this.renderer.setStyle(this.el.nativeElement, 'margin', '0 auto');
      this.renderer.setStyle(this.el.nativeElement, 'height', avatarHeight + 'px');
      this.renderer.setStyle(this.el.nativeElement, 'font', avatarWidth / 2 + 'px Arial');
      this.renderer.setStyle(this.el.nativeElement, 'color', '#FFF');
      this.renderer.setStyle(this.el.nativeElement, 'textAlign', 'center');
      this.renderer.setStyle(this.el.nativeElement, 'lineHeight', avatarHeight + 'px');
      this.renderer.setStyle(this.el.nativeElement, 'borderRadius', '50%');

    } else {
      console.warn('Avatar needs exactly two initials!');
    }

    this.renderer.setProperty(this.el.nativeElement, 'innerText', initials);

  }

  private getInitials(name: string) {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  private calcNumberFromText(text: string): number { // calcNmberFromText("AA");
    const charCodes = text
      .split('') // => ["A", "A"]
      .map(char => char.charCodeAt(0)) // => [65, 65]
      .join(''); // => "6565"
    return parseInt(charCodes, 10);
  }

}
