import { NgModule } from '@angular/core';

import { SharedModule } from './../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './password/recover/recover.component';
import { ConfirmComponent } from './password/confirm/confirm.component';
import { RecoveryCodeComponent } from './recovery-code/recovery-code.component';
import { VerifyPasswordComponent } from './verify/verify-password.component';

@NgModule({
  declarations: [LoginComponent, RecoverComponent, ConfirmComponent, RecoveryCodeComponent, VerifyPasswordComponent],
  imports: [
    SharedModule
  ],
  exports: [
    LoginComponent,
    RecoverComponent,
    ConfirmComponent
  ]
})
export class AuthModule { }
