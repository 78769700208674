<div class="page-container">
  <div class="d-flex align-items-center align-items-center-ie bg-login bg-cover">
      <div class="fw">
          <div class="container container-xs">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="cardbox cardbox-flat text-white form-validate text-color">
                  <div class="cardbox-heading">
                      <div class="cardbox-title mt-2 text-center text-primary text-uppercase text-bold">Datenpool BIM Viewer</div>
                  </div>
                  <!-- LOGIN VIEW -->
                  <div class="cardbox-body" *ngIf="loginView === 'LOGIN'">
                      <div class="px-5">
                          <div class="form-group">
                              <input #username formControlName="username" class="form-control" type="text" [placeholder]="'login.user.placeholder' | translate" (keyup)="triggerInput($event)">
                              <span class="text-danger" *ngIf="loginForm.get('username').hasError('required') && loginForm.get('username').dirty">
                                {{ "login.user.message" | translate }}
                              </span>
                          </div>
                          <div class="form-group">
                              <input formControlName="password" class="form-control" type="password" [placeholder]="'login.password.placeholder' | translate" (keyup)="triggerInput($event)">
                              <span class="text-danger" *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').dirty">
                                {{ "login.password.message" | translate }}
                              </span>
                          </div>
                          <div class="form-group">
                            <span class="text-danger" *ngIf="errorState.isActive">{{ "login.message-" + errorState.status.toLowerCase() | translate }}</span>
                          </div>
                          <div class="form-group mt-4" *ngIf="errorState.status !== 'EXPIRED'">
                            <div class="custom-control custom-checkbox mb-0">
                                <input formControlName="rememberMe" class="custom-control-input" id="logcheck" type="checkbox">
                                <label class="custom-control-label text-color" for="logcheck">{{ "login.keepMeLoggedIn" | translate }}</label>
                            </div>
                          </div>
                          <div class="text-center my-4">
                            <button *ngIf="errorState.status !== 'EXPIRED'" [disabled]="!loginForm.valid || loginForm.pristine || isLoading" class="btn btn-lg btn-gradient btn-oval btn-primary btn-block" type="submit">
                              <em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em> 
                              <span *ngIf="!isLoading">{{ "login.login" | translate }}</span>
                            </button>
                            <button *ngIf="errorState.status === 'EXPIRED'" (click)="onSetNewPassword()" type="button" class="btn btn-lg btn-gradient btn-oval btn-primary btn-block">
                              {{ "auth.misc.change-password" | translate }}
                            </button>
                          </div>
                      </div>
                      <div class="text-center text-color">
                        <small *ngIf="errorState.status === 'EXPIRED'" (click)="onCancelNewPassword()">
                          <a class="text-inherit">
                            {{ "auth.misc.return-login" | translate }}
                          </a>
                        </small>
                        <small *ngIf="errorState.status !== 'EXPIRED'">
                          <a class="text-inherit" [routerLink]="'/pwd/recover'">
                            {{ "login.forgotPassword" | translate }}
                          </a>
                        </small>
                      </div>
                  </div>
                  <!-- VERIFICATION VIEW -->
                  <div class="cardbox-body text-color text-center px-5" *ngIf="loginView === 'VERIFY'">
                    <app-verify-password
                      [isLoading]="isLoading"
                      [codeLength]="6"
                      [isResendingCode]="isResendingCode"
                      (resendCode)="onResendCode()"
                      (verifyPassword)="onVerifyPassword($event)"
                    >
                    </app-verify-password>
                  </div>
                  <!-- RECOVERY VIEW -->
                  <div class="cardbox-body text-color text-center" *ngIf="loginView === 'RECOVERY'">
                    <app-recovery-code
                      [isLoading]="isLoading"
                      [codeLength]="16"
                      (recoverAccount)="onVerifyPassword($event)"
                    >
                    </app-recovery-code>
                  </div>
                  <div class="cardbox-footer text-center text-color text-sm" *ngIf="loginView === 'LOGIN'; else footerRecoveryCodes">
                    <span class="mr-1">{{ "login.noAccountYet" | translate }}</span>
                    <a class="text-inherit" href="https://www.datenpool.at/kontakt" target="_blank">
                        <strong>{{ "login.registerNow" | translate }}</strong>
                    </a>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>

<!-- alternative footer that is displayed if verification step is active -->
<ng-template #footerRecoveryCodes>
  <div class="cardbox-footer text-center text-color text-sm d-flex flex-column">
    <!-- SWITCH BETWEEN VERIFICATION AND RECOVERY VIEW -->
    <div>
      <span class="mr-1" *ngIf="loginView === 'VERIFY'">{{ "auth.recovery-codes.no-access" | translate }}</span>
      <a class="text-inherit" href="javascript:;" (click)="loginView === 'RECOVERY' ? switchView('VERIFY') : switchView('RECOVERY')">
          <strong>{{ loginView === 'VERIFY' ? translatorService.instant("auth.recovery-codes.no-access-addendum") : translatorService.instant("auth.misc.return-verification") }}</strong>
      </a>
    </div>
    <!-- RETURN TO LOGIN -->
    <a class="text-inherit mt-1" href="javascript:;" *ngIf="loginView === 'VERIFY'" (click)="switchView('LOGIN')">
      <strong>{{ "auth.misc.return-login" | translate }}</strong>
    </a>
  </div>
</ng-template>

<toaster-container [toasterconfig]="toasterConfig"></toaster-container>