<div bsModal #searchModal="bs-modal" (onShown)="onModalShown()" class="modal modal-top fade modal-search">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-search-form">
                    <form action="#">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button (click)="searchModal.hide()" class="btn btn-flat" type="button" data-dismiss="modal">
                                    <em class="ion-md-arrow-round-back icon-lg text-muted"></em>
                                </button>
                            </div>
                            <input class="form-control header-input-search" type="text" [placeholder]="'header.search.placeholder' | translate">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
