<h4>{{ "auth.recovery-codes.use" | translate }}</h4>

<p>{{ "auth.recovery-codes.use-description" | translate }}</p>

<code-input [isCodeHidden]="false" [isCharsCode]="true" [inputType]="'text'" [initialFocusField]="0" [codeLength]="codeLength" [isFocusingOnLastByClickIfFilled]="true"
  (codeChanged)="onCodeInputChange($event)" class="mx-auto col-sm-12 col-md-12 col-lg-12">
</code-input>

<button [disabled]="!isEnabled || isLoading" (click)="recover()" class="btn btn-lg btn-gradient btn-oval btn-primary btn-block mt-3" type="button">
  <em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em>
  <span *ngIf="!isLoading">{{ "auth.verification.title" | translate }}</span>
</button>
