import { OssService } from 'src/app/core/services/oss.service';
import { BimModel } from './../../core/models/bim-model';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { SettingsService } from '../../shared/settings/settings.service';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';

import { Observable, Subject } from 'rxjs';
import { concatMap, concatMapTo, filter, first, shareReplay, startWith, takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { ModelService } from 'src/app/core/services/model.service';
import { ViewerService } from 'src/app/core/services/viewer.service';

import { User } from 'src/app/core/models/user';
import { Bucket } from 'src/app/core/models/bucket';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TranslatorService } from 'src/app/core/translator/translator.service';
import { TwoFactorAuthenticationService } from 'src/app/core/services/two-factor-authentication.service';
import { TwoFactorSetupEnabled } from 'src/app/core/models/2fa-setup-enabled';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss', './header.menu-links.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();

    /** Configuration settings used for *@sweetalert2* functions */
    private sweetAlertOptions: SweetAlertOptions = {
        icon: 'warning',
        confirmButtonColor: '#448AFF',
        allowOutsideClick: false,
        heightAuto: false,
        backdrop: 'url("assets/img/login-bg.jpg")'
    };

    buckets: Bucket[] = [];
    selectedBucket: Bucket;

    models: BimModel[] = [];
    selectedModel: BimModel;

    sidebarVisible: true;
    currentUser$: Observable<User>;
    isCurrentRouteSetToViewer: boolean;
    currentViewerState$: Observable<boolean>;

    constructor(private activatedRoute: ActivatedRoute,
                private authService: AuthService,
                private ossService: OssService,
                private mfaService: TwoFactorAuthenticationService,
                private modelService: ModelService,
                public pt: PagetitleService,
                private router: Router,
                public settings: SettingsService,
                private translatorService: TranslatorService,
                private viewerService: ViewerService) { }

    ngOnInit(): void {

        const is2FAEnabled$ = this.mfaService.is2FAEnabled().pipe(
            first(),
            shareReplay(1)
        );

        is2FAEnabled$.subscribe((setupEnabled: TwoFactorSetupEnabled) => {
            if (setupEnabled.is2FAMandatory && !setupEnabled.is2FAEnabled) {
                this.launchMandatory2FADialog();
            }
        });

        this.currentUser$ = is2FAEnabled$.pipe(
            filter((setupEnabled: TwoFactorSetupEnabled) => setupEnabled.is2FAMandatory ? setupEnabled.is2FAEnabled : true),
            concatMap(() => this.authService.getCurrentUser())
        );

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(this.router)
            )
            .subscribe((event: NavigationEnd) => {
                this.isCurrentRouteSetToViewer = event.url.includes('/viewer');
            });

        this.currentViewerState$ = this.viewerService.getViewerStatus();

        this.initializeModelChangeListener();

        is2FAEnabled$
            .pipe(
                filter((setupEnabled: TwoFactorSetupEnabled) => setupEnabled.is2FAMandatory ? setupEnabled.is2FAEnabled : true)
            )
            .subscribe(() => this.loadBuckets());
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    toggleSidebar(state = null): void {
        //  state === true -> open
        //  state === false -> close
        //  state === null -> toggle
        this.settings.app.sidebar.visible = state !== null ? state : !this.settings.app.sidebar.visible;
    }

    toggleSidebarCoverModeVisible(): void {
        this.settings.app.sidebar.coverModeVisible = !this.settings.app.sidebar.coverModeVisible;
    }

    onOpenViewer(): void {
        this.viewerService.addModelUrn(this.selectedModel.lastBimActions.translation.urn);
        this.viewerService.setViewerStatus(true);
    }

    onAddAdditionaldModelToViewer(): void {
        this.viewerService.addModelUrn(this.selectedModel.lastBimActions.translation.urn);
        this.viewerService.addAdditionalModel(this.selectedModel);
    }

    isSelectedInViewer(): boolean {
        return this.viewerService.getModelUrns().includes(this.selectedModel.lastBimActions.translation.urn);
    }

    onSelectBucket(): void {
        this.ossService.setSelectedBucket(this.selectedBucket);
        this.viewerService.resetModelUrns();
        this.viewerService.setViewerStatus(false);
        this.loadModels();
    }

    onSelectModel(): void {
        this.modelService.setSelectedModel(this.selectedModel);
    }

    logout(): void {
        this.authService.logout();
        this.clearNotesSession();
    }

    // Helper Methods

    private clearNotesSession(): void {
        window.location.href =  environment.BASE_PROJECT_URL + '/names.nsf?logout&redirectto=' + environment.BASE_BIM_URL + '/login';
    }

    private loadBuckets(): void {
        const buckets$ = this.currentUser$.pipe(
            filter(user => !!user),
            concatMap((user) => this.ossService.getBuckets(user.id)),
            takeUntil(this.unsubscribe$)
        );

        buckets$.subscribe(buckets => {
            if (buckets.length === 0) {
                this.launchNoBucketsDialog();
            } else {
                this.buckets = buckets;
                this.selectedBucket = buckets[0];

                if (this.activatedRoute.snapshot.queryParamMap.has('pk')) {
                    this.setBucketKeyFromUrlQuery(buckets);
                }

                this.ossService.setSelectedBucket(this.selectedBucket);
                this.loadModels();
            }
        });
    }

    private setBucketKeyFromUrlQuery(buckets: Bucket[]): void {
        const projectKey = this.activatedRoute.snapshot.queryParamMap.get('pk');
        const bucket: Bucket = buckets.find(b => b.bucketKey === projectKey);
        if (bucket) { this.selectedBucket = bucket; }
    }

    private loadModels(): void {
        this.modelService.getModelsWithValidUrn(this.selectedBucket.bucketKey).pipe(takeUntil(this.unsubscribe$)).subscribe(
            models => {
                this.models = models;
                this.selectedModel = models[0];
                this.modelService.setSelectedModel(this.selectedModel);
                this.modelService.setCurrentModelListIsEmpty(false);
            },
            err => {
                this.models = [];
                this.modelService.setCurrentModelListIsEmpty(true);
            }
        );
    }

    /**
     * If a model has been successfully translated, this method triggers a reload
     * of the model list.
     */
    private initializeModelChangeListener(): void {
        this.modelService.onUpdateModelList().subscribe(() => this.loadModels());
    }

    /**
     * Launches a @SweetAlert2 dialog, informing the user that no buckets could be found.
     *
     * As that means that no action can be taken by the user, the only option they are presented with
     * is to logout.
     */
    private launchNoBucketsDialog(): void {
        this.sweetAlertOptions.title = this.translatorService.instant('viewer.no-buckets-dialog.title');
        this.sweetAlertOptions.text = this.translatorService.instant('viewer.no-buckets-dialog.body');
        this.sweetAlertOptions.confirmButtonText = this.translatorService.instant('viewer.no-buckets-dialog.btn-logout');
        Swal.fire(this.sweetAlertOptions).then(() => this.logout());
    }

    private redirectTo2FASetup(): void {
        window.location.href = 'https://dashboard.datenpool.at/settings/security/two-factor-auth';
    }

    private launchMandatory2FADialog(): void {
        this.sweetAlertOptions.title = this.translatorService.instant('auth.setup.activate-mandatory');
        this.sweetAlertOptions.text = this.translatorService.instant('auth.setup.activate-mandatory-text');
        this.sweetAlertOptions.showDenyButton = true;
        this.sweetAlertOptions.denyButtonText = this.translatorService.instant('header.logout');
        this.sweetAlertOptions.confirmButtonText = this.translatorService.instant('auth.setup.activate-short');
        Swal.fire(this.sweetAlertOptions).then((result) => {
            if (result.isConfirmed) {
                this.redirectTo2FASetup();
            }
            if (result.isDenied) {
                this.logout();
            }
        });
    }

}
