import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { TranslatorService } from 'src/app/core/translator/translator.service';

/**
 * Enum describing the current state of the password reset operation.
 */
export enum STATE { IDLE, SUCCESS, ERROR }

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit, AfterViewInit {
    /**
     * Reference to the <tt>email</tt> input field
     */
    @ViewChild('email', { static: false }) email: ElementRef;
    currentState: STATE = STATE.IDLE;
    isLoading = false;
    recForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private translatorService: TranslatorService,
                private authService: AuthService,
                private userService: UserService,
                private router: Router) {

        // redirect to home if already logged in
        if (this.authService.isLoggedIn()) {
          this.router.navigate(['/']);
      }
    }

    submitForm(): void {
      this.isLoading = true;

      this.userService.resetPassword(this.recForm.get('email').value)
        .pipe(
          first(),
          tap(() => this.currentState = STATE.SUCCESS),
          catchError(err => {
            this.currentState = STATE.ERROR;
            if (err.status === 404) {
              // Legacy solution used for older variant of validation. Will be removed in a future release
              this.currentState = STATE.SUCCESS;
            }
            return throwError(err);
          }),
          finalize(() => this.isLoading = false)
        )
        .subscribe(() => this.recForm.reset());
    }

    ngOnInit() {
      this.recForm = this.formBuilder.group({
        email: [null, Validators.compose([Validators.required, Validators.email])]
      });
    }

    ngAfterViewInit() {
      this.email.nativeElement.focus();
    }

    /**
     * Enables access to the STATE enum
     */
    get state(): typeof STATE {
      return STATE;
    }

}
