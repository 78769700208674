import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { JwtService } from './../services/jwt.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private jwtService: JwtService, private authService: AuthService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isPathToExclude(req.url)) {
            return next.handle(req);
        }

        const currentUserToken = this.jwtService.getToken();

        if (currentUserToken && this.jwtService.isValidToken(currentUserToken)) {
            console.log('isValidToken!');
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUserToken}`
                }
            });

        } else if (currentUserToken && !this.jwtService.isValidToken(currentUserToken)) {
            console.log('invalid currentUserToken!');
            this.authService.logout();
            this.router.navigateByUrl('/auth/login');

        }

        return next.handle(req);
    }

    /**
     * Method checks whether the provided request url requires an access token
     * @param requestUrl Url to be checked
     */
    private isPathToExclude(requestUrl: string): boolean {
        const pathsToExclude = ['auth/login', 'auth/anonymous', 'auth/password/reset', 'auth/password/reset/confirm'];
        for (const p of pathsToExclude) {
            if (requestUrl.indexOf(p) > -1) { return true; }
        }
        return false;
    }
}
