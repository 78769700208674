import { Component, OnInit, Injector, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var $: any;

import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../shared/settings/settings.service';
import { Observable } from 'rxjs';

import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

    srcLogo = 'assets/img/Datenpool-Logo-white-letters.png';
    menu: Array<any>;
    router: Router;
    currentUser$: Observable<User>;

    constructor(private menuService: MenuService, public settings: SettingsService,
                private injector: Injector, private authService: AuthService) {

        this.menu = this.menuService.getMenuSorted();
    }

    ngOnInit(): void {
        this.router = this.injector.get(Router);
        this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event) => {
                this.settings.app.sidebar.visible = false;
                this.settings.app.sidebar.coverModeVisible = false;
            });

        this.currentUser$ = this.authService.getCurrentUser();
        this.settings.onSettingsChange()
            .pipe(filter((setting: string) => !!setting && setting.startsWith('theme')))
            .subscribe(setting => {
                if (setting === 'theme-dark-2') {
                    this.srcLogo = 'assets/img/Datenpool-Logo.png';
                } else {
                    this.srcLogo = 'assets/img/Datenpool-Logo-white-letters.png';
                }
            });
    }

    closeSidebar(): void {
        this.settings.app.sidebar.coverModeVisible = false;
        this.settings.app.sidebar.visible = false;
    }

    handleSidebar(event): void {
        const item = this.getItemElement(event);
        // check click is on a tag
        if (!item) { return; }

        const ele = $(item);
        const liparent = ele.parent()[0];

        const lis = ele.parent().parent().children(); // markup: ul > li > a
        // remove .active from childs
        lis.find('li').removeClass('active');
        // remove .active from siblings ()
        $.each(lis, function(key, li) {
            if (li !== liparent) {
                $(li).removeClass('active');
            }
        });
        const next = ele.next();
        if (next.length && next[0].tagName === 'UL') {
            ele.parent().toggleClass('active');
            event.preventDefault();
        }
    }

    // find the a element in click context
    // doesn't check deeply, asumens two levels only
    getItemElement(event): any {
        const element = event.target;
        const parent = element.parentNode;

        if (element.tagName.toLowerCase() === 'a') {
            return element;
        }
        if (parent.tagName.toLowerCase() === 'a') {
            return parent;
        }
        if (parent.parentNode.tagName.toLowerCase() === 'a') {
            return parent.parentNode;
        }
    }
}
