import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BimModel } from '../models/bim-model';
import { UpdateModelRequest } from '../models/update-model-request';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  private selectedModel$ = new BehaviorSubject<BimModel>(null);

  private isCurrentModelListEmpty$ = new BehaviorSubject<boolean>(false);
  private onUpdateModelList$ = new Subject();

  constructor(private httpClient: HttpClient) { }

  getModels(bucketKey: string, userId?: string): Observable<BimModel[]> {
    if (userId !== undefined) {
      return this.httpClient.get<BimModel[]>(`modelmgmt/buckets/${bucketKey}/models?approverId=${userId}`);
    }
    return this.httpClient.get<BimModel[]>(`modelmgmt/buckets/${bucketKey}/models`);
  }

  getModelsWithValidUrn(bucketKey: string): Observable<BimModel[]> {
    return this.httpClient.get<BimModel[]>(`modelmgmt/buckets/${bucketKey}/models`).pipe(
      map(models => models.filter(model => model.lastBimActions.translation)
    ));
  }

  /**
   * Updates the backend entry for the specified model.
   * @param umr Update model object. Field are defined in the *UpdateModelRequest* interface
   */
  update(bucketKey: string, id: string, umr: UpdateModelRequest): Observable<any> {
    const request: FormData = new FormData();
    Object.entries(umr).forEach(([key, value]) => request.append(key, value));
    return this.httpClient.patch(`modelmgmt/buckets/${bucketKey}/models/${id}`, request);
  }

  setSelectedModel(model: BimModel): void {
    this.selectedModel$.next(model);
  }

  getSelectedModel(): Observable<BimModel> {
    return this.selectedModel$.asObservable();
  }

  setCurrentModelListIsEmpty(isEmpty: boolean): void {
    this.isCurrentModelListEmpty$.next(isEmpty);
  }

  /** Indicates wheter the current model list-- based on the selected bucket-- is empty */
  isCurrentModelListEmpty(): Observable<boolean> {
    return this.isCurrentModelListEmpty$.asObservable();
  }

  /** Notifies active subscribers of the updated model list */
  updateModelList(): void {
    this.onUpdateModelList$.next();
  }

  /** Listen to changes in the model list. Currently that includes newly translated models. */
  onUpdateModelList(): Observable<any> {
    return this.onUpdateModelList$.asObservable();
  }

}
