/*!
 *
 * BIM Viewer - Datenpool based on
 * Dasha - Bootstrap Admin Template
 *
 * Version: @version@
 * Author: @author@
 * Website: @url@
 * License: @license@
 *
 */

import './modernizr.js'; // 'npm run modernizr' to create this file;

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

let p = platformBrowserDynamic().bootstrapModule(AppModule);
p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); })
// .catch(err => console.error(err));
