<div class="page-container">
    <div class="d-flex align-items-center align-items-center-ie bg-recover bg-cover">
        <div class="fw">
            <div class="container container-xs">
                <form [formGroup]="confirmationForm" (submit)="submitForm()" class="cardbox cardbox-flat form-validate text-color text-center" name="confirmationForm">
                    <div class="cardbox-heading">
                        <div class="cardbox-title mt-2 text-center text-primary text-uppercase text-bold">{{ "recover-pwd.header-confirm" | translate }}</div>
                    </div>
                    <div class="cardbox-body">
                        <div class="px-5">
                            <ng-container *ngIf="!success$ && !error$">
                                <div class="form-group">                                
                                    <div class="input-group">
                                        <input #password tabindex="1" formControlName="password" [formControl]="confirmationForm.controls['password']" class="form-control" [type]="inputVisibility.pw ? 'text' : 'password'" [placeholder]="'recover-pwd.hint-pw' | translate">
                                        <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button" (click)="toggleVisibility('pw')">
                                            <em [class]="inputVisibility.pw ? 'ion-ios-eye icon-lg text-primary' : 'ion-ios-eye-off icon-lg text-primary'"></em>
                                        </button>
                                        </div>
                                    </div>
                                    <span class="text-danger" *ngIf="confirmationForm.get('password').hasError('required') && confirmationForm.get('password').dirty">
                                        {{ "recover-pwd.vld-pw" | translate }}
                                    </span>
                                </div>
                                <div class="form-group">                                
                                    <div class="input-group">
                                        <input tabindex="2" formControlName="confirmPassword" [formControl]="confirmationForm.controls['confirmPassword']" class="form-control" [type]="inputVisibility.confirm ? 'text' : 'password'" [placeholder]="'recover-pwd.hint-pw-confirm' | translate">
                                        <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button" (click)="toggleVisibility('confirm')">
                                            <em [class]="inputVisibility.confirm ? 'ion-ios-eye icon-lg text-primary' : 'ion-ios-eye-off icon-lg text-primary'"></em>
                                        </button>
                                        </div>
                                    </div>            
                                    <span class="text-danger" *ngIf="confirmationForm.get('confirmPassword').hasError('required') && confirmationForm.get('confirmPassword').dirty">
                                        {{ "recover-pwd.vld-pw" | translate }}
                                    </span>
                                    <span class="text-danger" *ngIf="confirmationForm.get('confirmPassword').hasError('pattern') && confirmationForm.get('confirmPassword').dirty && !confirmationForm.errors?.passwordsNotEqual">
                                        {{"recover-pwd.vld-pw-pattern" | translate}}
                                    </span>
                                    <span class="text-danger" *ngIf="confirmationForm.errors?.passwordsNotEqual && !confirmationForm.get('confirmPassword').hasError('required') && confirmationForm.get('confirmPassword').dirty">
                                        {{ "recover-pwd.vld-pw-confirm" | translate }}
                                    </span>
                                </div>
                                <div class="text-center my-4">
                                    <button [disabled]="!confirmationForm.valid || confirmationForm.pristine" class="btn btn-lg btn-gradient btn-oval btn-primary btn-block" type="submit">
                                        {{ "recover-pwd.btn-confirm" | translate }}
                                    </button>
                                </div>
                            </ng-container>

                            <!-- SUCCESS message -->
                            <div *ngIf="success$ | async" class="text-center">
                                <em class="ion-ios-checkmark-circle-outline icon-4x text-success"></em>
                                <p class="text-success"><strong>{{ "recover-pwd.msg-confirm-success" | translate }}</strong></p>
                            </div>

                            <!-- ERROR message -->
                            <ng-container *ngIf="error$ | async as error">
                                <div *ngIf="error.type === 'UNDEFINED' || error.type === 'BADREQUEST'" class="text-center">
                                    <em class="ion-ios-close-circle-outline icon-4x text-danger"></em>
                                    <p class="text-danger"><strong>{{ "auth.signup.error.misc" | translate }}</strong></p>
                                </div>
    
                                <div *ngIf="error.type === 'INVALID'" class="text-center">
                                    <em class="ion-ios-close-circle-outline icon-4x text-danger"></em>
                                    <p class="text-danger"><strong>{{ "auth.signup.error.used" | translate }}</strong></p>
                                </div>
    
                                <div *ngIf="error.type === 'EXPIRED'" class="text-center">
                                    <em class="ion-ios-close-circle-outline icon-4x text-danger"></em>
                                    <p class="text-danger"><strong>{{ "auth.signup.error.expired" | translate }}</strong></p>
                                </div>
                            </ng-container>

                            <!-- LOADING message -->
                            <div class="text-center" *ngIf="isLoading">
                                <div class="spinner-grow text-primary m-3" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                <p>{{ "recover-pwd.msg-load" | translate }}</p>
                            </div>
                        </div>
                        <div class="text-center text-color" *ngIf="error$"><small><a class="text-inherit" [routerLink]="'/login'">{{ "recover-pwd.lnk-login" | translate }}</a></small></div>
                    </div>
                </form>    
            </div>
        </div>
    </div>
</div>

