const headingMenu = {
    order: 0,
    name: 'NAVIGATION',
    heading: true
};

const Viewer = {
    name: 'Viewer',
    link: '/viewer',
    iconclass: 'ion-ios-easel',
    order: 1
};

const Models = {
    name: 'Models',
    link: '/models',
    iconclass: 'ion-ios-filing',
    order: 2
};

export const menu = [
    headingMenu,
    Viewer,
    Models
];
