<h4>{{ "auth.verification.verify-code" | translate }}</h4>

<p>{{ "auth.verification.instructions" | translate }}</p>

<h5>{{ "auth.verification.code" | translate }}</h5>

<code-input [isCodeHidden]="false" [code]="'number'" [codeLength]="codeLength" [initialFocusField]="0" [isFocusingOnLastByClickIfFilled]="true"
  (codeChanged)="onCodeInputChange($event)" class="mx-auto col-sm-12 col-md-9 col-lg-9">
</code-input>

<button
    type="button"
    tooltip="{{ translatorService.instant('auth.verification.resend') }}"
    class="btn btn-outline-primary btn-sm mt-3"
    [disabled]="isResendingCode"
    (click)="onResendCode()"
>
    <span *ngIf="isResendingCode">
        <em class="spinner-border spinner-border-sm"></em>
    </span>
    <em class="ion-ios-refresh" *ngIf="!isResendingCode"></em>
</button>

<button [disabled]="!isEnabled || isLoading" (click)="verify()" class="btn btn-lg btn-gradient btn-oval btn-primary btn-block mt-3" type="button">
  <em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em>
  <span *ngIf="!isLoading">{{ "auth.verification.title" | translate }}</span>
</button>
