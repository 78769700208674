import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslatorService } from 'src/app/core/translator/translator.service';

@Component({
  selector: 'app-verify-password',
  templateUrl: './verify-password.component.html',
  styleUrls: ['./verify-password.component.scss']
})
export class VerifyPasswordComponent {
  /** Notify the calling component that a verification code has been input */
  @Output() verifyPassword: EventEmitter<string> = new EventEmitter();
  @Output() resendCode: EventEmitter<string> = new EventEmitter();

  @Input() isLoading: boolean;
  @Input() codeLength: number;
  @Input() isResendingCode: boolean;
  isEnabled: boolean;
  inputCode: string;

  constructor(public translatorService: TranslatorService) { }

  onCodeInputChange(code: string): void {
    if (code.length === this.codeLength) {
      this.inputCode = code;
      this.isEnabled = true;
    } else {
      this.isEnabled = false;
    }
  }

  onResendCode(): void {
    this.resendCode.emit();
  }

  verify(): void {
    this.verifyPassword.emit(this.inputCode);
  }

}
