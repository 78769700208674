import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Bucket } from '../models/bucket';

@Injectable({
  providedIn: 'root'
})
export class OssService {

  private selectedBucket$ = new BehaviorSubject<Bucket>(null);

  constructor(private httpClient: HttpClient) { }

  getBuckets(participantId?: string): Observable<Bucket[]> {
    if (participantId !== undefined) {
      return this.httpClient.get<Bucket[]>(`oss/buckets?participantId=${participantId}`);
    }
    return this.httpClient.get<Bucket[]>(`oss/buckets`);
  }

  setSelectedBucket(bucket: Bucket): void {
    this.selectedBucket$.next(bucket);
  }

  getSelectedBucket(): Observable<Bucket> {
    return this.selectedBucket$.asObservable();
  }

  upload(bucketKey: string, id: string, objectName: string): Observable<any> {
    return this.httpClient.put<any>(`oss/buckets/${bucketKey}/models/${id}/objects/${objectName}`, { });
  }

}
