<div class="layout-container" [ngClass]="layout()">
    <!-- top navbar-->
    <app-header></app-header>
    <!-- sidebar-->
    <app-sidebar></app-sidebar>
    <div (click)="closeSidebar()" class="sidebar-layout-obfuscator"></div>
    <!-- Main section-->
    <main class="main-container">
        <!-- Page content-->
        <section class="section-container">
            <router-outlet></router-outlet>
        </section>
        <!-- Page footer-->
        <app-footer></app-footer>
    </main>
</div>
