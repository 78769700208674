<div bsModal #settingsModal="bs-modal" class="modal-settings modal modal-right fade">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="mt-0 modal-title"><span>{{ "settings.header" | translate }}</span></h4>
                <div class="float-right clickable" (click)="settingsModal.hide()"><em class="ion-md-close icon-lg text-soft"></em></div>
            </div>
            <div class="modal-body">
                <p>Dark Mode</p>
                <div class="d-flex flex-wrap mb-3">
                    <div class="setting-color">
                        <label class="preview-theme-dark">
                            <input type="radio" name="settingTheme" [(ngModel)]="selectedTheme" (change)="updateTheme()" value="theme-dark"><span class="ion-md-checkmark"></span><span class="square24 b"></span>
                        </label>
                    </div>
                    <div class="setting-color">
                        <label class="preview-theme-dark-2">
                            <input type="radio" name="settingTheme" [(ngModel)]="selectedTheme" (change)="updateTheme()" value="theme-dark-2"><span class="ion-md-checkmark"></span><span class="square24 b"></span>
                        </label>
                    </div>
                    <div class="setting-color">
                        <label class="preview-theme-default">
                            <input type="radio" name="settingTheme" [(ngModel)]="selectedTheme" (change)="updateTheme()" value="theme-default"><span class="ion-md-checkmark"></span><span class="square24 b"></span>
                        </label>
                    </div>
                </div>
                <hr>
                <div class="custom-control custom-checkbox mb-3">
                    <input class="custom-control-input" id="checkcoverMode" (ngModelChange)="onSettingChange($event, 'hideNavigation')" [(ngModel)]="settings.app.sidebar.coverMode" type="checkbox">
                    <label class="custom-control-label" for="checkcoverMode">{{ "settings.sidebar.coverMode" | translate }}</label>
                </div>
                <div class="custom-control custom-checkbox mb-3">
                    <input class="custom-control-input" id="check-showtoolbar" (ngModelChange)="onSettingChange($event, 'showUserProfile')" [(ngModel)]="settings.app.sidebar.showtoolbar" type="checkbox">
                    <label class="custom-control-label" for="check-showtoolbar">{{ "settings.sidebar.profile" | translate }}</label>
                </div>
                <div class="custom-control custom-checkbox mb-3">
                    <input class="custom-control-input" id="check-fixed" (ngModelChange)="onSettingChange($event, 'fixFooter')" [(ngModel)]="settings.app.footer.fixed" type="checkbox">
                    <label class="custom-control-label" for="check-fixed">{{ "settings.footer" | translate }}</label>
                </div>
                <hr>
                <button type="button" #fsbutton (click)="toggleFullScreen()" class="btn btn-secondary">{{ "settings.fullscreen" | translate }}</button>
                <hr>
                <p>{{ "settings.language.label" | translate }}</p>
                
                <!-- START Language list-->
                <select class="language-select custom-select form-control"
                        [(ngModel)]="selectedLanguage"
                        (change)="translator.useLanguage(selectedLanguage)">
                    <option *ngFor="let lang of translator.getAvailableLanguages()" [value]="lang.code">
                        <!-- {{lang.text}} -->
                        {{ "settings.language.code." + lang.code | translate }}
                    </option>
                </select>
                <!-- END Language list-->
                <hr>
                <p>Version {{ appVersion }}</p>
            </div>
        </div>
    </div>
</div>
