import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'header-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    @ViewChild('searchModal', { static: true }) public searchModal: ModalDirective;

    constructor(private element: ElementRef) { }

    ngOnInit() { }

    onModalShown() {
        const input = this.element.nativeElement.querySelector('.header-input-search');
        if (input) { input.focus(); }
    }
}
