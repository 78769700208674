import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

const screenfull = require('screenfull');
declare var $: any;

import { SettingsService } from '../../shared/settings/settings.service';
import { TranslatorService } from '../../core/translator/translator.service';

import { environment } from './../../../environments/environment';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    @ViewChild('fsbutton', { static: true }) fsbutton;
    @ViewChild('settingsModal', { static: true }) public settingsModal: ModalDirective;

    public appVersion = environment.appVersion;
    public selectedTheme = 'theme-default';
    public selectedLanguage = 'de';

    constructor(public settings: SettingsService, public translator: TranslatorService) { }

    ngOnInit() {
        this.initSettings();
    }

    updateTheme() {
        this.settings.setSetting('theme', this.selectedTheme);
        this.settings.storeSettingInLocalStorage('currentTheme', this.selectedTheme);

        $('body')
            .removeClass((index, css) => (css.match(/(^|\s)theme-\S+/g) || []).join(' '))
            .addClass(this.settings.getSetting('theme'));
    }

    toggleFullScreen() {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }
    }

    onSettingChange(value: boolean, key: string): void {
        this.settings.storeSettingInLocalStorage(key, value.toString());
    }

    private initSettings(): void {
        // set language
        this.selectedLanguage = this.translator.getCurrentLangCode();
        // set theme
        if (localStorage.getItem('currentTheme')) {
            this.selectedTheme = localStorage.getItem('currentTheme');
            this.updateTheme();
        }
    }

}
