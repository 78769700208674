import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { catchError, finalize, first } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { TranslatorService } from 'src/app/core/translator/translator.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { ToasterConfig, ToasterService } from 'angular2-toaster';

declare let gtag: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
    /** Configuration settings used for the angular2-toaster container */
    public toasterConfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-bottom-right',
        timeout: 0,
        limit: 5,
        mouseoverTimerStop: true
    });

    @ViewChild('username', { static: false }) username: ElementRef;

    loginForm: FormGroup;
    returnUrl: string;
    /** Value exists if 2FA is enabled. Necessary for verification process */
    requestId: string;
    isLoading: boolean;
    isResendingCode: boolean;
    loginView: 'LOGIN' | 'VERIFY' | 'RECOVERY' = 'LOGIN';
    errorState: { isActive: boolean, status: 'NONE' | 'UNAUTHORIZED' | 'LOCKED' | 'EXPIRED' | 'MFA_MANDATORY', resetToken: string } = {
        isActive: false,
        status: 'NONE',
        resetToken: ''
    };

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private jwtService: JwtService,
                private toasterService: ToasterService,
                public translatorService: TranslatorService) {

        // redirect to home if already logged in
        if (authService.isLoggedIn()) {
            this.router.navigate(['/']);
        }

    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
            rememberMe: true
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    }

    ngAfterViewInit(): void {
        if (this.loginView === 'LOGIN') {
            this.username.nativeElement.focus();
        }
    }

    // convenience getter for easy access to form fields
    get f(): any { return this.loginForm.controls; }

    onSubmit(): void {

        this.loginForm.markAllAsTouched();
        this.resetErrorMsg();
        this.isLoading = true;
        this.jwtService.setRememberMe(this.loginForm.get('rememberMe').value);

        this.authService.login(this.f.username.value, this.f.password.value)
            .pipe(
                first(),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401 && err.error) {
                        this.errorState.isActive = true;
                        this.errorState.status = err.error.status;

                        if (this.errorState.status === 'EXPIRED') {
                            this.errorState.resetToken = err.error.resetToken;
                        }
                    }
                    return EMPTY;
                }),
                finalize(() => this.isLoading = false)
            )
            .subscribe(
                (res: any) => {
                    if (res.token) {
                        gtag('event', 'login', {
                        login_method: "2FA disabled"});
                        this.router.navigate([this.returnUrl]);
                    } else if (res.requestId) {
                        // 2FA enabled
                        gtag('event', 'login', {
                        login_method: "2FA enabled"});
                        this.requestId = res.requestId;
                        this.switchView('VERIFY');
                    }
                });
    }

    onVerifyPassword(code: string): void {
        this.isLoading = true;
        this.authService.verify(code, this.requestId)
            .pipe(
                first(),
                catchError(() => {
                    this.onError({
                        title: this.translatorService.instant('auth.verification.error'),
                        body: this.translatorService.instant('auth.verification.error-try-again')
                    });
                    return EMPTY;
                }),
                finalize(() => this.isLoading = false)
            )
            .subscribe(() => this.router.navigate([this.returnUrl]));
    }

    onResendCode(): void {
        this.isResendingCode = true;
        this.authService.resendVerificationCode(this.requestId)
            .pipe(
                first(),
                catchError(() => {
                    this.onError({
                        title: this.translatorService.instant('auth.verification.error-resend'),
                        body: this.translatorService.instant('auth.verification.error-try-again')
                    });
                    return EMPTY;
                }),
                finalize(() => this.isResendingCode = false)
            )
            .subscribe(() => {
                this.onSuccess({
                    title: this.translatorService.instant('auth.verification.success'),
                    body: this.translatorService.instant('auth.verification.success-resend')
                });
            });
    }

    onSetNewPassword(): void {
        this.router.navigate(['/pwd/confirm'], { queryParams: { token: this.errorState.resetToken }} );
    }

    onCancelNewPassword(): void {
        this.resetErrorMsg();
        this.loginForm.get('username').reset();
        this.loginForm.get('password').reset();
    }

    // HELPER METHODS

    onSuccess(event: { title: string, body: string }): void {
        this.toasterService.pop('success', event.title, event.body);
    }

    onError(event: { title: string, body: string }): void {
        this.toasterService.pop('error', event.title, event.body);
    }

    // Method is triggered once a user login failed and they type in different data in the form field
    triggerInput(event: any): void {
        if (event.keyCode !== 13 && this.errorState.isActive) {
            this.resetErrorMsg();
        }
    }

    switchView(view: 'LOGIN' | 'VERIFY' | 'RECOVERY'): void {
        this.loginView = view;
        this.isLoading = false;
    }

    // PRIVATE METHODS

    private resetErrorMsg(): void {
        this.errorState = {
            isActive: false,
            status: 'NONE',
            resetToken: ''
        };
    }

}
