import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpBackend } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

import { APIInterceptor } from './core/interceptors/api.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { Title } from '@angular/platform-browser';

// https://github.com/ocombe/ng2-translate/issues/218
// https://stackoverflow.com/questions/50565769/how-to-disable-interceptor-in-exported-function
// when injected, HttpBackend dispatches requests directly to the backend, without going through the interceptor chain.
export function createTranslateLoader(handler: HttpBackend) {
    const http = new HttpClient(handler);
    return new TranslateHttpLoader(http, './assets/static/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        // BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpBackend]
            }
        })
    ],
    providers: [
        Title,
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
