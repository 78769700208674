import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { JwtService } from './jwt.service';
import { User } from '../models/user';
import { UserService } from './user.service';
import { LoginCredentials } from '../models/login-credentials';
import { Jwtoken } from '../models/jwtoken';
import { environment } from 'src/environments/environment';

const AUTH_API_URL = environment.BASE_AUTH_URL;

@Injectable({ providedIn: 'root' })
export class AuthService {

  private currentUser$: Observable<User>;
  private reloadUser$ = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private userService: UserService) {

      this.initCurrentUser();
    }

  login(username: string, password: string): Observable<{ token?: string, requestId?: string }> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', skip: 'true' })
    };

    const loginCredentials: LoginCredentials = { username, password };
    const body = this.toFormDataString(loginCredentials);

    return this.http.post<any>(AUTH_API_URL + `/login`, body, httpOptions)
      .pipe(
        map(res => {
          if (res.token) {
            this.jwtService.saveToken(res.token);
            this.reloadCurrentUser();
          }
          return res;
        })
      );
    }

  verify(code: string, requestId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', skip: 'true' })
    };
    const body = this.toFormDataString({ code, requestId });

    return this.http.post(AUTH_API_URL + '/2fa/verify', body, httpOptions)
      .pipe(
        map((res: { token: string }) => {
          this.jwtService.saveToken(res.token);
          this.reloadCurrentUser();
        })
      );
  }

  resendVerificationCode(requestId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', skip: 'true' })
    };
    const body = this.toFormDataString({ requestId });

    return this.http.post(AUTH_API_URL + '/2fa/send-verification-code', body, httpOptions);
  }

  logout(): void {
    this.jwtService.destroyToken();
    this.reloadCurrentUser();
  }

  isLoggedIn(): boolean {
    return this.jwtService.getToken() && this.jwtService.isValidToken(this.jwtService.getToken() ) ? true : false;
  }

  getCurrentUser(): Observable<User> {
    return this.currentUser$;
  }

  /**
   * Calling this method prompts the cached **currentUser$** Observable
   * to be reloaded and thus updated.
   *
   * Active subscribers will be notified of the changes.
   */
  reloadCurrentUser(): void {
    this.reloadUser$.next(null);
  }

  getAutodeskToken(): Observable<Jwtoken> {
    return this.http.get<any>(`auth/token`)
      .pipe(
        map(token => {
          if (token) {
            console.log('token' + token);
          }
          console.log('auth.service.getToken END ' + token);
          return token;
        })
      );
  }

  private initCurrentUser(): void {
    this.currentUser$ = this.reloadUser$.pipe(
      switchMap(() => {
        if (!this.jwtService.getToken()) {
          return of(null);
        }
        console.warn('user.service.getcurrentuser -> load and CACHE it');
        return this.userService.loadCurrentUser();
      }),
      shareReplay(1),
      tap(() => console.log('user.service.getcurrentuser -> get from CACHE:'))
    );
  }

  // converts object into body using the content type 'application/x-www-form-urlencoded'
  private toFormDataString(data: any): string {
    return Object.keys(data)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
      })
      .join('&');
  }

}
