import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

import { environment } from './../../../environments/environment';
import { CookieOptions } from '../models/cookie-options';

@Injectable({ providedIn: 'root' })
export class JwtService {

  private static readonly JWT_TOKEN_NAME = 'currentUserToken';

  // tslint:disable-next-line: max-line-length
  private static readonly COOKIE_OPTIONS_REMEMBER_ME: CookieOptions = { expires: 7, path: '/', domain: environment.DOMAIN, secure: true, sameSite: 'Strict' };
  // tslint:disable-next-line: max-line-length
  private static readonly COOKIE_OPTIONS_SESSION: CookieOptions = { path: '/', domain: environment.DOMAIN, secure: true, sameSite: 'Strict' };

  private jwtHelper = new JwtHelperService();
  private rememberMe: boolean;

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  getToken(): string {
    return this.cookieService.get(JwtService.JWT_TOKEN_NAME);
  }

  saveToken(token: string): void {
    if (this.rememberMe) {
      this.cookieService.set(JwtService.JWT_TOKEN_NAME, token, JwtService.COOKIE_OPTIONS_REMEMBER_ME);
    } else {
      this.cookieService.set(JwtService.JWT_TOKEN_NAME, token, JwtService.COOKIE_OPTIONS_SESSION);
    }
  }

  destroyToken(): void {
    this.cookieService.delete(JwtService.JWT_TOKEN_NAME, '/', environment.DOMAIN, true, 'Strict');
  }

  persistToken(projectKey: string): Observable<any> {
    const params = new HttpParams().set('projectKey', projectKey);
    return this.http.get<any>(`/token/persist-current-token`, { params });
  }

  isValidToken(token: string): boolean {
    // helper for filtering invalid tokens (v1.0.0)
    // JWT strings must contain exactly 2 period characters
    if (token.split('.').length !== 3) {
      console.log('JWT strings must contain exactly 2 period characters!');
      return false;
    }

    // const decodedToken = this.jwtHelper.decodeToken(token);
    // const expirationDate = this.jwtHelper.getTokenExpirationDate(token);

    if (this.jwtHelper.isTokenExpired(token)) {
      console.log('JWT is expired!');
      return false;
    }

    return true;
  }

  setRememberMe(rememberMe: boolean): void {
    this.rememberMe = rememberMe;
  }

}
