<header class="header-container">
  <nav>
    <ul class="d-lg-none">
      <li>
        <a (click)="toggleSidebar()" class="sidebar-toggler menu-link menu-link-close">
          <span><em></em></span>
        </a>
      </li>
    </ul>
    <ul class="d-none d-sm-block">
      <li>
        <a (click)="toggleSidebarCoverModeVisible()" class="covermode-toggler menu-link menu-link-close">
          <span><em></em></span>
        </a>
      </li>
    </ul>
    <h2 [innerHTML]="pt.getTitle()" class="header-title"></h2>
    <ul class="float-right">
      <li>
        <div class="form-inline my-2 my-lg-0 inline">
          <select *ngIf="buckets.length > 0" [(ngModel)]="selectedBucket" (change)="onSelectBucket()" class="form-control mr-sm-2">
            <option *ngFor="let b of buckets" [ngValue]="b">
              {{b.name}}
            </option>
          </select>

          <select [(ngModel)]="selectedModel" (change)="onSelectModel()" class="form-control mr-sm-2" *ngIf="isCurrentRouteSetToViewer && models.length > 0">
            <option *ngFor="let m of models" [ngValue]="m">
              {{m.description}}
            </option>
          </select>
          <button type="button" class="btn btn-outline-danger my-2 my-sm-0"
                  *ngIf="isCurrentRouteSetToViewer && !(currentViewerState$ | async) && models.length > 0"
                  (click)="onOpenViewer()">
            {{ "viewer.btn-open-model" | translate }}
          </button>
          <button type="button" class="btn btn-outline-danger my-2 my-sm-0"
                  *ngIf="isCurrentRouteSetToViewer && (currentViewerState$ | async) && models.length > 0"
                  (click)="onAddAdditionaldModelToViewer()" [disabled]="isSelectedInViewer()">
            {{ "viewer.btn-add-model" | translate }}
          </button>
        </div>
      </li>
      <li><a (click)="seetingsBar.settingsModal.show()"><em class="ion-ios-more"></em></a></li>
      <li><a href="#" (click)="logout()" class="ion-ios-log-out icon-2x" [title]="'header.logout' | translate"></a></li>
    </ul>
  </nav>
</header>
<header-search #headerSearch></header-search>
<app-settings #seetingsBar></app-settings>
