import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, finalize, first, ignoreElements, shareReplay } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { TranslatorService } from 'src/app/core/translator/translator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  success$: Observable<any>;
  error$: Observable<any>;
  token: string;
  isLoading = false;
  inputVisibility: { pw: boolean, confirm: boolean } = {
    pw: false,
    confirm: false
  };
  confirmationForm: FormGroup;
  /**
   * Reference to the <tt>password</tt> input field
   */
  @ViewChild('password', { static: false }) password: ElementRef;

  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private translatorService: TranslatorService,
              private router: Router) {

    const isLoggedIn = this.authService.isLoggedIn();
    const hasValidToken = route.snapshot.queryParamMap.has('token') && route.snapshot.queryParamMap.get('token').length > 0;

    if (isLoggedIn || !hasValidToken) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');

    this.initForm();
  }

  toggleVisibility(event: string): void{
    if (event in this.inputVisibility) {
      this.inputVisibility[event] = !this.inputVisibility[event];
    }
  }

  submitForm(): void {
    this.isLoading = true;

    this.success$ = this.userService.confirmPasswordReset(this.token, this.confirmationForm.get('password').value)
      .pipe(
        first(),
        shareReplay(),
        finalize(() => this.isLoading = false)
      );

    this.error$ = this.success$.pipe(
      ignoreElements(),
      catchError(err => this.handleValidationError(err))
    );

    this.success$.subscribe(() => {
      setTimeout(() => {
        this.clearNotesSession();
      }, 2000);
    });
  }

  private initForm(): void {
    this.confirmationForm = this.formBuilder.group({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?!\().*(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)])
    }, { validators: this.checkPasswords });
  }

  /**
   * Custom validation method that checks
   * whether the new passwords put in by the user are identical
   * @param control AbstractControl entity holding the password value
   * @returns null if the two password inputs are equal, otherwise an error object
   */
  private checkPasswords(control: AbstractControl): ValidationErrors | null {
      const password = control.get('password').value;
      const confirmPassword = control.get('confirmPassword').value;
      return password === confirmPassword ? null : { passwordsNotEqual: true };
  }

  private handleValidationError(err: HttpErrorResponse): Observable<any> {
    const error: { type: 'UNDEFINED' | 'BADREQUEST' | 'INVALID' | 'EXPIRED' } = { type: 'UNDEFINED' };

    switch (err.status) {
      case 400:
        error.type = 'BADREQUEST';
        break;
      case 409:
        error.type = 'INVALID';
        break;
      case 422:
        error.type = 'EXPIRED';
        break;
      default:
        error.type = 'UNDEFINED';
    }
    return of(error);
  }

  private clearNotesSession(): void {
    window.location.href =  environment.BASE_PROJECT_URL + '/names.nsf?logout';
  }
}
