import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
declare var $: any;

@Injectable()
export class SettingsService {

    private onSettingsChange$ = new Subject();
    app: any;

    constructor() {

        // Global Settings
        // -----------------------------------
        this.app = {
            name: 'BIM Viewer | Datenpool',
            description: 'Template used for Datenpool related projects',
            layout: {
                rtl: false
            },
            sidebar: {
                showtoolbar: true, // profile area in sidebar
                visible: false, // mobile sidebar visible
                coverMode: false,  // cover mode
                coverModeVisible: false // cover mode sidebar visible
            },
            footer: {
                fixed: false
            },
            theme: 'theme-default' // no actively used
        };

        this.applyUserSettings();

    }

    getSetting(name) {
        return name ? this.app[name] : this.app;
    }

    setSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
            this.onSettingsChange$.next(value);
        }
    }

    toggleSetting(name) {
        return this.setSetting(name, !this.getSetting(name));
    }

    /**
     * Methods persists the specified key-value pair in the window.localStorage Storage
     * @param key Key of the setting to store
     * @param value Value of the setting to store
     */
    storeSettingInLocalStorage(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    onSettingsChange(): Observable<any> {
        return this.onSettingsChange$.asObservable();
    }

    /**
     * Method updatesn the global 'app' settings according to
     * the user's persisted preferences
     */
    private applyUserSettings(): void {
        if (localStorage.getItem('hideNavigation') !== null) {
            this.app.sidebar.coverMode = localStorage.getItem('hideNavigation') === 'true';
        }
        // set user profile visibility
        if (localStorage.getItem('showUserProfile') !== null) {
            this.app.sidebar.showtoolbar = localStorage.getItem('showUserProfile') === 'true';
        }
        // set footer
        if (localStorage.getItem('fixFooter') !== null) {
            this.app.footer.fixed = localStorage.getItem('fixFooter') === 'true';
        }
    }

}
