import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './../core/guards/auth.guard';
import { RecoverComponent } from './auth/password/recover/recover.component';
import { ConfirmComponent } from './auth/password/confirm/confirm.component';

export const routes: Routes = [

    // routes loaded using the application layout (with sidebar, header, etc)
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'viewer', pathMatch: 'full', data: { title: 'BIM Viewer | Datenpool' } },
            { path: 'viewer', canActivate: [AuthGuard], data: { title: 'BIM Viewer | Datenpool' },
                loadChildren: () => import('./viewer/viewer.module').then(m => m.ViewerModule)
            },
            { path: 'models', canActivate: [AuthGuard], data: { title: 'BIM Models | Datenpool' },
                loadChildren: () => import('./models/models.module').then(m => m.ModelsModule)
            }
        ]
    },

    // Pages displayed without sidebar, header, etc.
    { path: 'login', component: LoginComponent, data: { title: 'Login | Datenpool' } },

    { path: 'pwd/recover', component: RecoverComponent, data: { title: 'Forgot password | Datenpool' } },

    { path: 'pwd/confirm', component: ConfirmComponent, data: { title: 'Forgot password | Datenpool' } },

    // Not found
    { path: '**', redirectTo: 'viewer', data: { title: 'BIM Viewer | Datenpool' } }

];
