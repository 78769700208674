import { version } from '../../package.json';

export const environment = {
  production: false,
  appVersion: version,
  DOMAIN: '.datenpool.at',
  BASE_AUTH_URL: 'https://authdev.datenpool.at',
  BASE_API_URL: 'https://apibimdev.datenpool.at/bim',
  BASE_PROJECT_URL: 'https://projektedev.datenpool.at',
  BASE_BIM_URL: 'https://bimdev.datenpool.at'
};