import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private httpClient: HttpClient) { }

  loadCurrentUser(): Observable<User> {
    return this.httpClient.get<User>(`users/me`);
  }

  /**
   * Method triggers password reset process in backend by sending the user further
   * instructions to the provided email address.
   * @param email The address to which the next reset steps will be sent to
   */
  resetPassword(email: string): Observable<any> {
    const formData = new FormData();
    formData.append('email', email);

    return this.httpClient.put(`auth/password/reset`, formData, {observe : 'response'});
  }

  /**
   * After calling the <tt>UserService.resetPassword</tt> method,
   * a mail with a confirmation link is sent to the specified address.
   *
   * The link includes a secret key that is passed on to this method. Should the key be valid,
   * the user's password will be reset.
   *
   * Additionally, the user has to choose a new password which will be set upon confirming
   * the validity of the token.
   * @param token Secret key that is passed on to the backend
   * @param password The new password the user wants to set
   */
  confirmPasswordReset(token: string, password: string): Observable<any> {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('password', password);

    return this.httpClient.put(`auth/password/reset/confirm`, formData);
  }

}
