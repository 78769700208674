import { Component, Input, OnInit } from '@angular/core';

/**
 * Progress bar component that can be used universally by providing
 * the necessary inputs *progress* and *styleClass*.
 *
 * *progress* - Must be a number between 0 and 100.
 *
 * *styleClass* - Primarily used for defining the color of the progress bar. If none is provided,
 * the defaut class *bg-primary* will be used.
 */
@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number;
  @Input() styleClass: string;

  constructor() { }

  ngOnInit(): void { }

}
