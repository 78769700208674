import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { BimModel } from '../models/bim-model';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  private showViewer$ = new BehaviorSubject<boolean>(false);
  private onAdditionalModelAdded$ = new Subject<BimModel>();
  /** List of urns currently loaded in the Forge viewer */
  private activeModelUrns: string[] = [];

  setViewerStatus(status: boolean): void {
    this.showViewer$.next(status);
  }

  addAdditionalModel(model: BimModel): void {
    this.onAdditionalModelAdded$.next(model);
  }

  onAdditionalModelAdded(): Observable<any> {
    return this.onAdditionalModelAdded$.asObservable();
  }

  getViewerStatus(): Observable<boolean> {
    return this.showViewer$.asObservable();
  }

  /** Add a new urn to be displayed in the Forge viewer. */
  addModelUrn(urn: string): void {
    if (this.activeModelUrns.includes(urn)) { return; }

    this.activeModelUrns.push(urn);
  }

  /** Returns a list of urns currently loaded in the Forge viewer */
  getModelUrns(): string[] {
    return this.activeModelUrns;
  }

  /** Empties the list of current urns loaded in the Forge viewer */
  resetModelUrns(): void {
    this.activeModelUrns = [];
  }

}
