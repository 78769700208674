import { Component, ViewChild, OnDestroy, ElementRef, AfterViewInit, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { Jwtoken } from 'src/app/core/models/jwtoken';
import { AuthService } from 'src/app/core/services/auth.service';

// We need to tell TypeScript that Autodesk exists as a variables/object somewhere globally
declare const Autodesk: any;

@Component({
  selector: 'app-forge-viewer',
  templateUrl: './forge-viewer.component.html',
  styleUrls: ['./forge-viewer.component.scss'],
})
export class ForgeViewerComponent implements OnDestroy, AfterViewInit {
  @ViewChild('viewerContainer', {static: false}) viewerContainer: any;
  public viewer: any;
  @Input() urns: string[];

  constructor(public elementRef: ElementRef, private authService: AuthService) { }

  ngAfterViewInit(): void {
    this.launchViewer();
  }

  ngOnDestroy(): void {
    this.destroyViewer();
  }

  private launchViewer(): void {
    console.log('launchViewer');

    if (this.viewer) {
      return;
    }

    const options = {
      env: 'AutodeskProduction',
      api: 'derivativeV2',  // for models uploaded to EMEA change this option to 'derivativeV2_EU'
      getAccessToken: (onTokenReady: any) => { this.getAccessToken(onTokenReady); }
    };

    Autodesk.Viewing.Initializer(options, () => {
      const htmlDiv = this.viewerContainer.nativeElement;
      this.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv); // Headless viewer
      this.viewer.setTheme('light-theme');
      console.log('start viewer');
      const startedCode = this.viewer.start();
      if (startedCode > 0) {
          console.error('Failed to create a Viewer: WebGL not supported.');
          return;
      }
      console.log('Initialization complete, loading models next...');
      this.loadModels();
    });

  }

  private destroyViewer(): void {
    if (this.viewer && this.viewer.running) {
      console.log('viewer is running -> destroyViewer');
      this.viewer.finish();
      this.viewer = null;
      Autodesk.Viewing.shutdown();
    }
  }

  private getAccessToken(onTokenReady: any): void {
    this.authService.getAutodeskToken().pipe(first()).subscribe((token: Jwtoken) => {
      onTokenReady(token.access_token, token.expires_in);
    });
  }

  public loadModel(urn: string): void {
    // Document urn e.g. urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bmJzLW5pbWJ1cy9sYWtlc2lkZS5ydnQ
    const documentId  = 'urn:' + urn;

    Autodesk.Viewing.Document.load(documentId,
        (viewerDocument: any) => {
          const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
          const options = {
            // https://forge.autodesk.com/blog/loading-multiple-models-forge-viewer-v7
            keepCurrentModels: true,
            globalOffset: {x: 0, y: 0, z: 0}
          };
          this.viewer.loadDocumentNode(viewerDocument, defaultModel, options);
        },
        () => console.error('Failed fetching Forge manifest')
    );
  }

  public loadModels(): void {
    this.urns.forEach(u => {
      this.loadModel(u);
    });
  }

}
