import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class TranslatorService {

    defaultLanguage = 'de';
    availablelangs: any;
    currentLang: string = this.defaultLanguage;

    constructor(private translate: TranslateService) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(this.defaultLanguage);

        this.availablelangs = [
            { code: 'de', text: 'Deutsch' },
            { code: 'en', text: 'Englisch' },
            { code: 'cs', text: 'Tschechisch' },
            { code: 'uk', text: 'Ukrainisch' }
        ];

        this.useLanguage();
    }

    useLanguage(lang: string = localStorage.getItem('currentLang') ? localStorage.getItem('currentLang') : this.defaultLanguage) {
        this.translate.use(lang);
        this.currentLang = lang;
        localStorage.setItem('currentLang', lang);
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

    /**
     * Returns the text property (ie. German, English, ... ) of the currently selected language
     */
    getCurrentLang() {
        for (const i in this.availablelangs) {
            if (this.availablelangs[i].code === this.currentLang) {
                return this.availablelangs[i].text;
            }
        }
    }

    /**
     * Returns the country code of the currently selected language
     */
    getCurrentLangCode(): string {
        return this.currentLang;
    }

    getTranslatedValue(key: string | Array<string>, interpolateParams?: object): Observable<any> {
        return this.translate.get(key, interpolateParams);
    }

    /**
     * Returns a translation instantly from the internal state of loaded translation.
     * All rules regarding the current language, the preferred language of even fallback languages will be used
     * except any promise handling.
     */
    instant(key: string | string[], interpolateParams?: object): any {
        return this.translate.instant(key, interpolateParams);
    }

}
