import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

    footerLinks = {
        contact: 'https://www.datenpool.at/kontakt',
        legalNotice: 'https://www.datenpool.at/impressum',
        privacyPolicy: 'https://www.datenpool.at/datenschutzerklaerung'
    };


    constructor() { }

    ngOnInit() {
        this.setFooterLinks();
    }

    private setFooterLinks(): void {
        if (localStorage.getItem('currentLang') !== null && localStorage.getItem('currentLang') !== 'de') {
            this.footerLinks.contact = 'https://www.datenpool.at/en/contact/';
            this.footerLinks.legalNotice = 'https://www.datenpool.at/en/imprint/';
            this.footerLinks.privacyPolicy = 'https://www.datenpool.at/en/privacy-policy/';
        }
    }

}
