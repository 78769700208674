import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-recovery-code',
  templateUrl: './recovery-code.component.html',
  styleUrls: ['./recovery-code.component.scss']
})
export class RecoveryCodeComponent {
  @Input() codeLength: number;
  @Input() isLoading: boolean;
  @Output() recoverAccount: EventEmitter<string> = new EventEmitter();
  isEnabled: boolean;
  inputCode: string;

  constructor() { }

  onCodeInputChange(code: string): void {
    if (code.length === this.codeLength) {
      this.inputCode = code;
      this.isEnabled = true;
    } else {
      this.isEnabled = false;
    }
  }

  recover(): void {
    this.recoverAccount.emit(this.inputCode);
  }

}
