import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { AuthService } from './core/services/auth.service';

declare var $: any;
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute,
                private authService: AuthService,
                private router: Router,
                private title: Title) { }

    ngOnInit(): void {
        // prevent empty links to reload page
        $(document).on('click', 'a[href="#"]', (e) => e.preventDefault());

        gtag('config', 'G-VZCGRWML6V', {
            send_page_view: false
        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.authService.getCurrentUser().pipe(
                    filter(u => !!u), take(1)
                ).subscribe(u => {
                    gtag('event', 'page_view', {
                        page_location: event.urlAfterRedirects,
                        page_title: event.urlAfterRedirects,
                        user_id: u.id
                    });
                });

                this.setPageTitle();
            });
    }

    private setPageTitle(): void {
        let pageTitle = this.title.getTitle();
        let child = this.activatedRoute.firstChild;

        while (child.firstChild) {
            child = child.firstChild;
        }
        if (child.snapshot.data.title) {
            pageTitle = child.snapshot.data.title;
        }

        this.title.setTitle(pageTitle);
    }
}
