<div class="page-container">
    <div class="d-flex align-items-center align-items-center-ie bg-recover bg-cover">
        <div class="fw">
            <div class="container container-xs">
                <form [formGroup]="recForm" (submit)="submitForm()" class="cardbox cardbox-flat form-validate text-color" name="recoverForm">
                    <div class="cardbox-heading">
                        <div class="cardbox-title mt-2 text-center text-primary text-uppercase text-bold">{{ "recover-pwd.header-reset" | translate }}</div>
                    </div>
                    <div class="cardbox-heading" *ngIf="currentState !== state.SUCCESS">
                        <p class="text-center text-inherit">{{ "recover-pwd.instructions" | translate }}</p>
                    </div>
                    <div class="cardbox-body text-center">
                        <div class="form-group text-center" *ngIf="!isLoading; else loading">
                            <div class="input-group" *ngIf="currentState !== state.SUCCESS; else msgsuccess">
                                <input #email formControlName="email" [formControl]="recForm.controls['email']" class="form-control" type="email" name="accountName" required="" [placeholder]="'recover-pwd.hint-email' | translate">
                                <div class="input-group-append">
                                    <button [disabled]="!recForm.valid || recForm.pristine" class="btn btn-primary btn-gradient" type="submit">{{ "recover-pwd.btn-reset" | translate }}</button>
                                </div>
                            </div>

                             <!-- mail sent error msg -->
                            <div *ngIf="currentState === state.ERROR && !isLoading" class="error-msg">
                                <p class="text-danger"><strong>{{ "recover-pwd.msg-error" | translate }}</strong></p>
                            </div>

                            <!-- validator messages -->
                            <div class="text-danger" *ngIf="recForm.controls['email'].hasError('required') && recForm.controls['email'].dirty">{{ "recover-pwd.vld-required" | translate }}</div>
                            <div class="text-danger" *ngIf="recForm.controls['email'].hasError('email') && recForm.controls['email'].dirty">{{ "recover-pwd.vld-email" | translate }}</div>
                        </div>
                        <div class="text-center text-color" *ngIf="!isLoading"><small><a class="text-inherit" [routerLink]="'/login'">{{ "recover-pwd.lnk-login" | translate }}</a></small></div>
                    </div>
                </form>

                <!-- messages success template -->
                <ng-template #msgsuccess>
                    <div *ngIf="currentState === state.SUCCESS && !isLoading">
                        <em class="ion-ios-checkmark-circle-outline icon-4x text-success"></em>
                        <p class="text-success line-break"><strong>{{ "recover-pwd.msg-success" | translate }}</strong></p>
                    </div>
                </ng-template>

                <!-- spinner template -->
                <ng-template #loading>
                    <div class="spinner-grow text-primary m-3" role="status">
                      <span class="sr-only"></span>
                    </div>
                    <p>{{ "recover-pwd.msg-load" | translate }}</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>
