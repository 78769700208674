import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TwoFactorSetupEnabled } from '../models/2fa-setup-enabled';

const AUTH_API_URL = environment.BASE_AUTH_URL;
const TWO_FACTOR_SETUP_API = '/2fa/setup';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorAuthenticationService {

  constructor(private http: HttpClient) { }

  is2FAEnabled(): Observable<TwoFactorSetupEnabled> {
    return this.http.get<TwoFactorSetupEnabled>(AUTH_API_URL + TWO_FACTOR_SETUP_API, { headers: { skip: 'true'} });
  }
}
